import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, IconButton, AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, CircularProgress, Divider, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {jwtDecode} from 'jwt-decode';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import PDFViewer from './PDFViewer'
const drawerWidth = 240;

const courses = [
  {
    title: "Scalping Mastery",
    sections: [
      {
        title: "Introduction",
        description: "This section provides an overview of the scalping strategy.",
        videos:[ "/videos/introduction.mp4"],
        pdfPath: null
      },
      {
        title: "Market Structure",
        description: "Learn about the market structure and how it impacts trading.",
        videos: ["https://astucefx.net/files/Scalping_Mastery/MarketStructure_Part1.mp4","https://astucefx.net/files/Scalping_Mastery/market_structure_part2.mp4"],
        pdfPath: null
      },
      {
        title: "Liquidity",
        description: "Understand liquidity and its role in the market.",
        videos: ["/videos/liquidity.mp4"],
        pdfPath: null
      },
      {
        title: "POI",
        description: "Points of Interest (POI) in trading.",
        videos: [],
        pdfPath: null
      },
      {
        title: "Confirmation Entry",
        description: "How to confirm entry points in trading.",
        videos: ["/videos/confirmation_entry.mp4"],
        pdfPath: null
      },
      {
        title: "Top down Analysis",
        description: "Performing top-down analysis for better trading decisions.",
        videos: ["/videos/top_down_analysis.mp4"],
        pdfPath: null
      },
      {
        title: "Setups",
        description: "Various trading setups and how to use them.",
        videos: ["/videos/setups.mp4"],
        pdfPath: null
      },
      {
        title: "Backtest Sessions",
        description: "Learn how to backtest trading strategies.",
        videos: ["https://astucefx.net/files/pingpong/Backtest.mp4"],
        pdfPath: null
      },
      {
        title: "Scalping pdf",
        description: "This section contains a PDF on scalping strategies.",
        videos: [],
        pdfPath: "https://astucefx.net/files/ScalpingPdf.pdf"
      },
    ]
  }
];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  backgroundColor: '#000000',
  minHeight: '100vh',
  color: '#ffffff',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  backgroundColor: '#000000',
  color: '#ffffff',
}));

const customTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#121212',
    },
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#00ff00',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
});

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false); // Drawer open state
  const [courseOpen, setCourseOpen] = useState(courses.map(() => false)); // Course outline open state
  const [selectedSection, setSelectedSection] = useState(null); // Currently selected section
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          // Decode the token to verify it's valid
          const user = jwtDecode(token);
          console.log('User:', user);
          setUser(user);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Invalid token:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv'); // Redirect to login page if not authenticated
    }
  }, [loading, isAuthenticated, navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCourseToggle = (index) => {
    setCourseOpen((prev) => {
      const newOpenState = [...prev];
      newOpenState[index] = !newOpenState[index];
      return newOpenState;
    });
  };

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear token from local storage
    navigate('/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv'); // Redirect to login page after logout
    console.log("Logged out");
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#000000',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#000000',
              color: '#ffffff',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['Home', 'Courses', 'Reviews'].map((text, index) => (
              <ListItem button key={text} onClick={() => navigate(`/${text.toLowerCase()}`)}>
                <ListItemIcon>
                  {index === 0 ? <HomeIcon style={{ color: '#ffffff' }} /> : index === 1 ? <SchoolIcon style={{ color: '#ffffff' }} /> : <RateReviewIcon style={{ color: '#ffffff' }} />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon style={{ color: '#ffffff' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
          <Divider />
          <List>
            {courses.map((course, index) => (
              <React.Fragment key={index}>
                <ListItem button onClick={() => handleCourseToggle(index)}>
                  <ListItemText primary={course.title} />
                  {courseOpen[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={courseOpen[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {course.sections
                      .filter((section) => user.pdf || !section.pdfPath)
                      .map((section, secIndex) => (
                        <ListItem key={secIndex} button sx={{ pl: 4 }} onClick={() => handleSectionSelect(section)}>
                          <ListItemText primary={section.title} />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
            <Typography variant="h4" gutterBottom>
              Welcome to your Dashboard
            </Typography>
            {selectedSection ? (
              <>
                <Typography variant="h5" gutterBottom>
                  {selectedSection.title}
                </Typography>
                <Typography variant="body1" paragraph>
                  {selectedSection.description}
                </Typography>
                {selectedSection.videos && selectedSection.videos.map((video, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <video width="100%" controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                ))}
                {selectedSection.pdfPath && user.pdf && (
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <PDFViewer pdfPath={selectedSection.pdfPath} />
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body1">
                Here is your main dashboard content.
              </Typography>
            )}
          </Container>
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
